import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setUser, userLoggedOut } from "store/auth/userSlice";
import { clearSettingData } from "store/settings/settingsSlice";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import useQuery from "./useQuery";
import axiosInstance from "apiServices/axiosInstance";
import { setThemeColor, setThemeColorLevel } from "store/theme/themeSlice";
import { themeConfig } from "configs/theme.config";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, expired } = useSelector((state) => state.auth.session);

  const onThemeColorChange = (value) => {
    dispatch(setThemeColor(value));
  };

  const onThemeColorLevelChange = (value) => {
    dispatch(setThemeColorLevel(value));
  };

  //signIn endpoint
  const signIn = async ({ email, password }) => {
    try {
      const formData = {
        affiliate_email: email,
        password: password,
      };
      const response = await axiosInstance.post(
        "affiliate/affiliate-sign-in",
        formData
      );
      if (response.status) {
        const { token, data } = response;
        dispatch(onSignInSuccess(token));
        const userData = data;
        userData &&
          dispatch(
            setUser({
              name: userData.affiliate_name
                ? userData.affiliate_name
                : "affiliate",
              email: userData.affiliate_email
                ? userData.affiliate_email
                : "demo@gmail.com",
              authority: userData.role
                ? [userData.role.toLowerCase()]
                : ["affiliate"],
              affiliate_id: userData.affiliate_id ? userData.affiliate_id : 0,
              password: userData.password ? userData.password : "",
            })
          );
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return response;
      } else {
        return response;
      }
    } catch (errors) {
      return {
        status: false,
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  //handle of  signOut 
  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(userLoggedOut());
    dispatch(clearSettingData());
    onThemeColorChange(themeConfig.themeColor);
    onThemeColorLevelChange(themeConfig.primaryColorLevel);
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
  };

  //check user login or not
  const checkAuthenticated = () => {
    if (expired > new Date().getTime()) {
      return true;
    } else {
      handleSignOut();
      return false;
    }
  };
  return {
    authenticated: token && signIn && checkAuthenticated(),
    signIn,
    signOut,
  };
}

export default useAuth;
